






































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { WebSocketLoggerEntityModel, WebSocketLoggerQueryModel } from '@/entity-model/websocket-logger-entity';
import WebSocketLoggerService from '@/service/websocket-logger.service';

@Component
export default class MessageTemplateListComponent extends TableDialogFormComponent<WebSocketLoggerEntityModel, WebSocketLoggerQueryModel> {
    WebSocketLoggerEntityModel = WebSocketLoggerEntityModel;
    created() {
        this.initTable({
            service: new WebSocketLoggerService(),
            queryModel: new WebSocketLoggerQueryModel(),
            tableColumns: WebSocketLoggerEntityModel.getTableColumns()
        });
        this.getList();
    }
}

