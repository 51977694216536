import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { ChannelTypeEnum } from '@common-src/entity-model/channel-entity';
import { Moment } from 'moment';
import { getEndTimeByIntervalType } from '@common-src/utils/time_uitil';
import { PeriodTypeEnum } from '@common-src/model/enum';

export class WebSocketLoggerEntityModel extends BaseEntityModel {
    static baseUrl = `infra-messagecenter/api/websocket/log`;

    batchId: string = undefined;
    channelName: string = undefined;
    templateName: string = undefined;
    channelType: ChannelTypeEnum = undefined;
    messageReceiver: string = undefined;
    messageReceiverType: string = undefined;
    messageReceiverName: string = undefined;
    templateId: string = undefined;
    sendTime: string = undefined;
    appName: string = undefined;
    remark: string = undefined;
    messageContent: string = undefined;
    modelStatus: number = null;
    status: number = null;
    typeDesc: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '应用名称',
                dataIndex: 'appName'
            },
            {
                title: '消息内容',
                dataIndex: 'messageContent',
                width: '40vw',
                scopedSlots: { customRender: 'messageContent' }
            }, {
                title: '接收人',
                dataIndex: 'messageReceiverName'
            }, {
                title: '消息类型',
                dataIndex: 'typeDesc'
            }, {
                title: '发送时间',
                dataIndex: 'sendTime'
            }, {
                title: '发送结果',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' }
            }, {
                title: '备注',
                dataIndex: 'remark'
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WebSocketLoggerQueryModel extends QueryPageModel {
    @QueryControl({
        label: '消息类型',
        type: QueryControlType.SELECT,
        options: [
            { value: 'RPC', name: '设备控制响应' },
            { value: 'MODEL_ALARM', name: '模型告警' },
            { value: 'EVENT', name: '事件' },
            { value: 'TELEMETRY', name: '时序数据' }
        ],
        span: 6
    })
    type: ChannelTypeEnum = undefined;

    @QueryControl({
        label: '应用名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    appName: string = undefined;

    @QueryControl({
        label: '发送时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 6
    })
    dateRange: Array<Moment> = [];

    toService() {
        const data = super.getParams();
        if (this.dateRange.length > 0) {
            data.params.start = this.dateRange[0].startOf('day').valueOf();
            data.params.end = Date.parse(getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.CUSTOM) + ' 23:59:59');
        }
        delete data.params.dateRange;
        return data;
    }
}
