var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edge-list-component table-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("ws日志")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-card",
          { staticClass: "table-query-card", attrs: { title: "日志查询" } },
          [
            _c("jtl-table-query-component", {
              ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
            }),
          ],
          1
        ),
        _c(
          "a-card",
          { attrs: { title: "日志列表" } },
          [
            _c("a-table", {
              attrs: {
                loading: _vm.listLoading,
                columns: _vm.tableColumns,
                "data-source": _vm.listData,
                pagination: _vm.Pagination,
                scroll: _vm.tableScroll,
                "row-key": _vm.TableRowKey,
                size: _vm.TableSize,
              },
              on: { change: _vm.tableChange },
              scopedSlots: _vm._u([
                {
                  key: "messageContent",
                  fn: function (text) {
                    return [
                      _c(
                        "div",
                        { staticClass: "ellipsis", attrs: { title: text } },
                        [_vm._v(_vm._s(text))]
                      ),
                    ]
                  },
                },
                {
                  key: "status",
                  fn: function (text, record) {
                    return [
                      record.status
                        ? _c("a", { staticClass: "jtl-del-link" }, [
                            _vm._v("失败"),
                          ])
                        : _c("span", [_vm._v("成功")]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }